// third-party
import { createSlice } from '@reduxjs/toolkit';
// import { dispatch } from 'store';


// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    cities: [],
    cityTax: {},
    roomTypes: {}
};

const slice = createSlice({
    name: 'settingsControl',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        getTaxCitiesSuccess(state, action) {
            state.cities = action.payload;
        },

        getCityTaxSuccess(state, action) {
            state.cityTax = action.payload;
        },

        getRoomTypesSuccess(state, action) {
            state.roomTypes = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTaxCities() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/api/settings/tourism-tax/cities');
            dispatch(slice.actions.getTaxCitiesSuccess(response.data.cities));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const createTaxLines = (taxes) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('/api/settings/tourism-tax', taxes);
            if(response.date.suyccess) {
                return true;
            } else {
                dispatch(slice.actions.hasError(response.data.error));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
};

export function getCityTax(city) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/api/settings/tourism-tax/${city}`);
            dispatch(slice.actions.getCityTaxSuccess(response.data.taxes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoomTypes() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/api/rooms/types');
            dispatch(slice.actions.getRoomTypesSuccess(response.data.types));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
