import { FormattedMessage } from 'react-intl';

import { IconTool, IconReceiptTax } from '@tabler/icons';

const settings = {
    id: 'settings',
    title: <FormattedMessage id="setings" />,
    type: 'group',
    icon: IconTool,
    children: [
        {
            id: 'user-settings',
            title: <FormattedMessage id="user-settings" />,
            type: 'item',
            url: '/user/account-profile/profile',
            icon: IconTool,
        },
        {
            id: 'tourism-tax-settings',
            title: <FormattedMessage id="tourism-tax-settings" />,
            type: 'item',
            url: '/settings/tourism-tax',
            roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
            icon: IconReceiptTax,
        }
    ]
}

export default settings;